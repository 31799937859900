.footer {
  text-align: center;
  background: var(--gradient-bg-dark);
  z-index: 3;
  position: relative;
}

.footer p {
  padding: 10px 0;
  color: var(--grey);
  font-family: var(--paragraph-title-font);
  font-size: 0.6rem;
}
