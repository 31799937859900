/* animation start */
.circle-animation {
  position: relative;
  width: 100vw;
  height: 1000px;
  background: radial-gradient(
    circle,
    var(--blue),
    var(--tale) 55%,
    var(--grey)
  );
}

.circle-animation:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: radial-gradient(
    circle,
    transparent,
    var(--grey) 55%,
    transparent
  );
  animation: color 2s infinite ease-out alternate;
  opacity: 0;
}

@keyframes color {
  0% {
    background: radial-gradient(
      circle,
      transparent,
      var(--grey) 55%,
      transparent
    );
  }
  100% {
    opacity: 1;
  }
}

/* animation end */

.home-offers-section-group {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 5rem 0;
  max-width: 600px;
  margin: auto;
}

.home-offers-section-group-title {
  width: 300px;
  margin: auto;
}

.home-offers-section-group-title img {
  width: 100%;
}

.btn-container {
  margin-bottom: 30px;
}

.btn-container .button .btn--primary {
  font-size: 1.3rem;
  min-width: 300px;
}

.home-offers-section-group-offer-group {
  font-family: var(--paragraph-font);
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
  color: var(--grey);
}

.home-offers-section-group-offer-group h3 {
  font-family: var(--secondary-title-font);
  font-size: 2.8rem;
}

.home-offers-section-group-offer-group-price {
  font-family: var(--secondary-title-font);
  font-size: 2rem;
  padding-top: 20px;
  font-weight: bold;
}

.home-offers-section-group-offer-group-price span {
  font-family: "Lato", sans-serif;
}

.home-offers-section-group-offer-group-percentage {
  font-family: var(--secondary-title-font);
  font-size: 2.5rem;
  font-weight: bold;
  padding-top: 20px;
}

.home-offers-section-group-offer-group-price-savings {
  margin-bottom: 30px;
  margin-top: 30px;
  padding: 0 20px;
}

.home-offers-section-group-footer {
  align-items: center;
  text-align: center;
  max-width: 550px;
  margin: auto;
}

.home-offers-section-group-footer .text {
  margin: 0 10px 50px 10px;
  padding: 10px;
  font-family: var(--secondary-title-font);
  color: var(--white);
  background: rgba(51, 152, 157, 0.3);
  font-size: 0.8rem;
  border-radius: 20px;
}

.home-offers-section-group-footer .text p:nth-child(2) {
  padding: 4px;
  font-size: 0.6rem;
}

@media screen and (min-width: 576px) {
  .home-offers-section-group-footer .text {
    margin: 0 20px;
    padding: 20px;
    font-size: 0.8rem;
    border-radius: 40px;
  }

  .home-offers-section-group-footer .text p:nth-child(2) {
    padding: 4px;
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 700px) {
  .carousel-item-container {
    min-height: 800px;
  }
}

@media screen and (min-width: 992px) {
  .carousel-item-container {
    min-height: 950px;
  }
}

@media screen and (min-width: 1200px) {
  .carousel-item-container {
    min-height: 1050px;
  }
}

@media screen and (min-width: 1440px) {
  .carousel-item-container {
    min-height: 1350px;
  }
}
