.about-me-page-container {
  padding-top: 150px;
  text-align: center;
}

.contact-info-icons div {
  margin: 15px;
}

.contacts-button-container {
  margin: 30px 0;
}

.home-contacts-button.section-top {
  margin-top: 50px;
}

.about-me-page-container img {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 100px 9px 100px -34px var(--tale);
  box-shadow: 100px 9px 100px -34px var(--tale);
}

.about-me-page-container
  .about-me-page-container-about-me-section-img2-container-reverse
  img {
  width: 100%;
  -webkit-box-shadow: 100px 9px 100px -34px var(--tale);
  box-shadow: -100px 9px 100px -34px var(--tale);
}

.about-me-page-container-about-me-section-img-container {
  margin-bottom: 50px;
}

.about-me-page-container-about-me-section-img2-container {
  align-items: center;
  margin-top: 50px;
}

#working-hours {
  margin-top: 50px;
}

.home-contacts-phone,
.home-contacts-address {
  display: flex;
  text-align: left;
  justify-content: left;
  color: var(--blue);
  font-size: var(--paragraph-font-size);
  font-family: var(--paragraph-font);
}

.home-contacts-phone svg,
.home-contacts-address svg {
  background-color: var(--blue);
  color: var(--white);
  border-radius: 50%;
  margin-right: 0.7rem;
  min-width: 3rem;
  min-height: 3rem;
  padding: 0.5rem;
}

.working-hours-container {
  max-width: 600px;
  margin: auto;
  padding: 150px 0;
}

.working-hours-container h3 {
  width: 100%;
  margin-bottom: 50px;
  font-family: var(--secondary-title-font);
  font-size: var(--title-font-size);
}

.working-hours-container-schedule {
  max-width: 350px;
  margin: auto;
}

.working-hours-container-schedule-item {
  display: flex;
  justify-content: space-between;
  font-family: var(--secondary-title-font);
  font-size: var(--paragraph-font-size);
  margin-top: 5px;
}

.member-off {
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--paragraph-font);
  font-size: var(--paragraph-font-size);
  color: var(--tale);
}

.member-off span {
  margin-bottom: 20px;
}

.member-off-img-section {
  display: flex;
  align-items: center;
}
.member-off-img-section img {
  width: 100px;
  margin: 0 10px;
}

.about-me-page-container-about-me-section {
  max-width: 400px;
  margin: auto;
}

.about-me-page-container-about-me-section h2 {
  font-family: var(--main-title-font);
  display: block;
  font-size: var(--title-font-size);
  font-weight: bold;
}

.about-me-page-container-about-me-section p {
  font-family: var(--paragraph-font);
  font-size: var(--paragraph-font-size);
  color: var(--tale);
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.working-hours-by-appointment-only {
  font-family: var(--paragraph-font);
  font-size: var(--paragraph-font-size);
  padding-bottom: 15px;
}

.about-me-page-container-contact-me-section-contacts {
  margin: auto;
  max-width: 300px;
  gap: 20px;
}

.about-me-page-container-contact-me-section h3 {
  width: 100%;
  margin-bottom: 50px;
  font-family: var(--secondary-title-font);
  font-size: var(--title-font-size);
  font-size: bold;
}

.about-me-page-container-where-to-find-me-section h3 {
  font-family: var(--secondary-title-font);
  font-size: var(--title-font-size);
  font-size: bold;
}

.about-me-page-container-where-to-find-me-section iframe {
  margin-bottom: 50px;
}

.about-me-page-container-where-to-find-me-section .home-contacts-address {
  max-width: 400px;
  margin: 40px auto;
}

.about-me-page-container iframe {
  width: 100%;
  height: 400px;
  border: none;
  border-radius: 10px;
  z-index: 1;
  position: relative;
}

.about-me-page-container-contact-me-section {
  margin: 50px auto;
  max-width: 400px;
}

.member-of-lines {
  display: flex;
  width: 400px;
  margin: 50px auto 50px auto;
  justify-content: space-between;
  align-items: center;
}
.payment-section {
  color: var(--tale);
  font-family: var(--paragraph-font);
  font-size: 1rem;
}

.member-of-lines div {
  background-color: var(--tale);
}

.member-of-lines-one {
  width: 120px;
  height: 2px;
  border-radius: 100%;
}
.member-of-lines-two {
  width: 5px;
  height: 5px;
  border-radius: 100%;
}
.member-of-lines-three {
  width: 7px;
  height: 7px;
  border-radius: 100%;
}
.member-of-lines-four {
  width: 5px;
  height: 5px;
  border-radius: 100%;
}
.member-of-lines-five {
  width: 120px;
  height: 2px;
  border-radius: 100%;
}

@media screen and (min-width: 700px) {
  .home-contacts-phone,
  .home-contacts-address {
    font-size: 1rem;
  }

  .home-contacts-phone svg,
  .home-contacts-address svg {
    min-width: 2rem;
    min-height: 2rem;
    padding: 0.4rem;
  }

  .about-me-page-container-contact-me-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 800px;
  }

  .about-me-page-container-contact-me-section-contacts {
    max-width: 400px;
    display: flex;
    gap: 20px;
  }

  .about-me-page-container-contact-me-section-contacts div:first-child {
    width: 100%;
  }
}

@media screen and (min-width: 756px) {
  .about-me-page-container-about-me-section {
    max-width: 900px;
  }

  .about-me-page-container-about-me-section-img-container {
    display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;
    align-items: center;
  }

  .about-me-page-container-about-me-section-img-container img {
    margin-left: 50px;
  }

  .about-me-page-container img {
    width: 250px;
    -webkit-box-shadow: 100px 59px 100px -34px var(--tale);
    box-shadow: 100px 59px 100px -34px var(--tale);
  }

  .about-me-page-container
    .about-me-page-container-about-me-section-img2-container-reverse
    img {
    width: 250px;
    -webkit-box-shadow: 100px 9px 100px -34px var(--tale);
    box-shadow: -100px -59px 100px -34px var(--tale);
  }

  .about-me-page-container-about-me-section-img2-container-reverse {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
  }

  .about-me-page-container-about-me-section-img2-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 50px 0;
  }

  .about-me-page-container-about-me-section-img2-container-reverse img {
    margin-right: 50px;
  }

  .about-me-page-container-about-me-section-img2-container img {
    margin-left: 50px;
  }

  .member-off img {
    width: 100px;
  }

  .member-off {
    margin: 50px auto;
  }
}

/* moving parts effect */
.circles-about-me {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles-about-me li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: var(--tale);
  animation: animate 25s linear infinite;
  bottom: -150px;
  border-radius: 100%;
}

.circles-about-me li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles-about-me li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles-about-me li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles-about-me li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles-about-me li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles-about-me li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles-about-me li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles-about-me li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles-about-me li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles-about-me li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
  }
}
