.about-services-container img {
  width: 100%;
}

.about-services-dark-section-background {
  background: var(--gradient-bg-dark);
  color: var(--grey);
}

.about-services-img-container {
  max-width: 400px;
  margin: auto;
}

.about-services-light-section-background {
  color: var(--tale);
}

.about-services-light-section-background h2 {
  color: var(--gradient-dark-text);
}

.about-services-container-first-section {
  padding: 140px 0;
}

.about-services-container-first-section h2 {
  font-family: var(--main-title-font);
  font-size: var(--title-font-size);
  margin-bottom: 30px;
  font-weight: bold;
  text-align: center;
}

.about-services-light-section-background.about-services-container-section-item.padding-bottom-about-services-bottom-section {
  padding-bottom: 50px;
}

.about-services-container-first-section h3 {
  text-align: center;
  font-family: var(--main-title-font);
  font-size: var(--sec-title-font-size);
  color: var(--lime);
  font-weight: bold;
  margin-bottom: 30px;
}

.about-services-container-first-section p {
  margin-bottom: 40px;
  color: var(--grey);
  font-family: var(--paragraph-font);
  font-size: var(--paragraph-font-size);
  text-align: center;
}

.about-services-container-section-item {
  padding: 100px 0;
}

.about-services-container-section-item-list {
  text-align: center;
}

.about-services-flex-container {
  margin-bottom: 70px;
  text-align: center;
}

.about-services-flex-container-reverse {
  margin-bottom: 70px;
  text-align: center;
}

.about-services-flex-container:last-child {
  margin-bottom: 0px;
}

.about-services-dark-section-background img {
  -webkit-box-shadow: 70px 40px 100px 4px var(--white);
  box-shadow: 70px 40px 100px 4px var(--white);
}

.about-services-light-section-background .about-services-flex-container img {
  -webkit-box-shadow: 100px 19px 100px -14px var(--tale);
  box-shadow: 100px 19px 100px -14px var(--tale);
}

.about-services-light-section-background
  .about-services-flex-container-reverse
  img {
  -webkit-box-shadow: -100px 19px 100px -14px var(--tale);
  box-shadow: -100px 19px 100px -14px var(--tale);
}

.about-services-container-section-item h3 {
  text-align: center;
  font-family: var(--main-title-font);
  font-size: var(--sec-title-font-size);
  margin-bottom: 30px;
}

.about-services-container-section-item p {
  font-family: var(--paragraph-font);
  font-size: var(--paragraph-font-size);
}

.round-circle-blue-dark {
  width: 20px;
  height: 20px;
  background: var(--gradient-dark-text);
  margin: 15px auto;
  border-radius: 100%;
}
.round-circle-light {
  width: 5px;
  height: 5px;
  background: var(--gradient-light-text);
  margin: 10px auto;
  border-radius: 100%;
}

.about-services-light-section-background.about-services-container-section-item {
  padding-bottom: 0;
  margin-bottom: 0;
}

.about-services-p-padding-bottom {
  margin-bottom: 30px;
}

.about-services-container-second-section-text,
.about-services-container-first-section-text {
  max-width: 650px;
  margin: auto;
}

.about-services-container-section-item-list.small-container,
.about-services-container-section-item-list-long.small-container {
  max-width: 800px;
  margin: auto;
  padding: 0 10px;
}

.about-services-container-section-item-list-long.small-container {
  text-align: center;
}

@media screen and (min-width: 792px) {
  .about-services-dark-section-background img {
    -webkit-box-shadow: 100px -89px 100px -24px var(--white);
    box-shadow: 100px -89px 100px -24px var(--white);
  }

  .about-services-light-section-background
    .about-services-flex-container-reverse
    img {
    -webkit-box-shadow: -100px -39px 100px -14px var(--tale);
    box-shadow: -100px -39px 100px -14px var(--tale);
  }

  .about-services-light-section-background .about-services-flex-container img {
    -webkit-box-shadow: 100px -39px 100px -14px var(--tale);
    box-shadow: 100px -39px 100px -14px var(--tale);
  }

  .about-services-flex-container {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin-top: 70px;
    text-align: center;
  }

  .about-services-flex-container-reverse {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    flex-direction: row-reverse;
    text-align: center;
  }

  .about-services-container-first-section-text {
    width: 50%;
    margin-right: 50px;
  }

  .about-services-flex-container-reverse
    .about-services-container-second-section-text {
    width: 60%;
    margin-left: 50px;
  }

  .about-services-flex-container .about-services-container-second-section-text {
    width: 60%;
    margin-right: 50px;
  }

  .about-services-img-container {
    max-width: 300px;
  }
}
