#home-about-me {
  background: var(--gradient-bg-dark);
  text-align: center;
}

.home-about-me-img {
  max-width: 550px;
  margin: auto;
}

.home-about-me-img img {
  /* border-radius: 50% 100% 50% 100%; */
  -webkit-box-shadow: 100px -89px 100px -24px var(--white);
  box-shadow: 100px -89px 100px -24px var(--white);
  width: 100%;
}

.home-about-me h2 {
  font-family: var(--main-title-font);
  display: block;
  font-size: var(--title-font-size);
  font-weight: 700;
  padding: 50px 0 20px 0;
}

.home-about-me p {
  margin-bottom: 40px;
  color: var(--grey);
  font-family: var(--paragraph-font);
  font-size: var(--paragraph-font-size);
}

@media screen and (min-width: 700px) {
  .home-about-me-img {
    max-width: 250px;
    margin-right: 40px;
  }

  .home-about-me-paragraph {
    max-width: 330px;
  }

  .home-about-me {
    display: flex;
    justify-content: center;
  }

  .home-about-me-img img {
    -webkit-box-shadow: 100px -89px 100px -24px var(--white);
    box-shadow: -100px 50px 100px -24px var(--white);
  }
}
