.home-affiliate-section {
  margin: 70px 0;
}

.home-affiliate-container {
  margin: auto;
  padding: 0 25px;
  max-width: 750px;
  text-align: center;
}

.home-affiliate-container h2 {
  font-family: var(--main-title-font);
  font-size: var(--title-font-size);
}

.home-affiliate-carousel-item {
  margin: 30px 0 60px 0;
}

.home-affiliate-carousel-item-text a {
  font-family: var(--secondary-title-font);
  text-transform: uppercase;
  font-size: var(--paragraph-font-size);
  color: var(--blue);
}

.home-affiliate-carousel-item-text a:hover {
  cursor: pointer;
}

.home-affiliate-carousel-item-text p {
  font-family: var(--paragraph-font);
  font-size: var(--paragraph-font-size);
  color: var(--blue);
  padding: 1rem 0;
}

.home-affiliate-carousel-item img {
  display: none;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 2px solid var(--tale);
}

.carousel .thumb {
  margin-right: 6px;
  white-space: nowrap;
  overflow: hidden;
  border: 2px solid #fff;
  padding: 2px;
  border-radius: 10px;
}

@media screen and (min-width: 710px) {
  .carousel .thumb {
    display: none;
  }

  .home-affiliate-carousel-item {
    display: flex;
    justify-items: center;
    align-items: center;
    align-content: center;
    padding: 0 30px;
    align-items: center;
    margin: 50px 0 100px 0;
  }

  .home-affiliate-carousel-item-text {
    padding-right: 15px;
    flex-grow: 1;
  }

  .home-affiliate-carousel-item img {
    display: block;
    max-width: 150px;
    border-radius: 20px;
  }
}
