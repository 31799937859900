#home.page-padding {
  padding-bottom: 10px;
}

h1 span {
  font-family: var(--main-title-font);
  display: block;
  font-size: var(--main-title-font-size);
  font-weight: bolder;
  text-align: center;
}

.home-description {
  max-width: 450px;
  margin: auto;
}

.home-description-logo {
  width: 300px;
  margin: auto;
}

.home-description-logo img {
  width: 100%;
}

.home-main-title {
  margin-top: 50px;
}

.home-description-title {
  max-width: 350px;
  margin: auto;
}

.home-description-title p {
  font-family: var(--paragraph-font);
  font-size: var(--paragraph-font-size);
  color: var(--blue);
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
  font-weight: bold;
}

.home-description-title .title-line {
  width: 60px;
  height: 0.5px;
  background-color: var(--blue);
  margin: 0 30px;
}

.home-description p {
  font-family: var(--paragraph-font);
  font-size: var(--main-secondary-title-font-size);
  color: var(--blue);
  padding-top: 2rem;
  text-align: center;
  font-weight: bold;
}

.home-main-image {
  max-width: 450px;
  margin: auto;
  padding-top: 50px;
}

.home-main-image img {
  width: 100%;
  -webkit-box-shadow: 0px 89px 100px -24px var(--tale);
  box-shadow: 40px -19px 100px -24px var(--tale);
}

.home-quote {
  max-width: 500px;
  margin: 50px auto 20px auto;
  text-align: center;
  font-family: var(--paragraph-font);
  font-size: var(--paragraph-font-size);
  font-weight: bold;
  color: var(--tale);
}

@media screen and (min-width: 576px) {
  .home-main-title {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
  }

  .home-main-image {
    padding: 0 0 0 20px;
    max-width: 280px;
  }

  .section-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    max-width: 750px;
    margin: auto;
  }

  .home-description-main-title {
    width: 100%;
  }
}

@media screen and (min-width: 700px) {
  .home-contacts {
    justify-content: center;
  }

  .home-main-image {
    max-width: 350px;
  }

  .home-description-title .title-line {
    width: 60px;
    height: 0.5px;
    background-color: var(--blue);
    margin: 0 0 0 30px;
  }
}

@media screen and (min-width: 770px) {
  .home-main-image {
    margin: auto 0 auto auto;
  }
}
