.services-service-item {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-content: center;
  justify-items: center;
  align-items: center;
  color: var(--tale);
  margin-bottom: 80px;
  max-width: 280px;
  background-color: var(--white);
  /* border-radius: 300px; */
  border-radius: 20px;
  overflow: hidden;
  min-height: 480px;
  -webkit-box-shadow: 0px -10px 35px 2px var(--white);
  box-shadow: 0px -10px 35px 2px var(--white);
  padding-bottom: 20px;
}

.services-service-item:hover {
  background-color: var(--grey);
  -webkit-box-shadow: 0px -10px 35px 2px var(--grey);
  box-shadow: 0px -10px 45px 2px var(--grey);
  cursor: pointer;
}

.services-service-item-overlay-content-item-price-list {
  display: flex;
  font-family: var(--paragraph-title-font);
  justify-content: center;
  font-size: var(--paragraph-font-size);
}

.services-service-item-text {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
}

.services-service-item-text-title {
  min-height: 87px;
}

.services-service-item-text h2 {
  text-align: center;
  padding: 0 10px;
  margin: 10px 0;
  font-size: 1.4rem;
  line-height: 1.5rem;
  font-family: var(--main-title-font);
}

.services-service-item-overlay-content-item h2 {
  font-family: var(--main-title-font);
  text-align: center;
  margin-bottom: 20px;
}

.services-service-item-text p {
  font-family: var(--paragraph-font);
  font-size: 1rem;
  margin-bottom: 5px;
  text-align: center;
}

.services-service-item-text .learn-more {
  margin-bottom: 20px;
  margin-top: 10px;
  color: var(--lime);
}

.services-service-item-text p span {
  font-family: var(--paragraph-title-font);
}

.services-service-item-img {
  overflow: hidden;
  position: relative;
}

.services-service-item-img img {
  width: 100%;
}

.services-service-item-overlay-content-item img {
  width: 100%;
  -webkit-box-shadow: 10px -29px 120px -14px var(--white);
  box-shadow: 10px -29px 120px -14px var(--white);
}

.services-service-item-overlay-content-item-img {
  width: 90%;
  max-width: 350px;
  margin: 0 auto 40px auto;
  padding-top: 80px;
  /* border-radius: 30% 100% 30% 100%; */
}

.services-service-item-overlay-container.hidden {
  display: none;
}

.services-service-item-overlay-container {
  position: fixed;
  color: white;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(51, 152, 157, 0.95);
  z-index: 12;
  overflow-x: scroll;
}

.services-service-item-overlay-content {
  width: 95%;
  max-width: 550px;
  margin: auto;
  padding: 0 20px;
  background-color: rgba(51, 152, 157, 1);
}

.services-service-item-overlay-content-item-close {
  position: fixed;
  top: 2%;
  right: 2%;
  cursor: pointer;
}

.services-service-item-overlay-content-item-close svg {
  height: 50px;
  width: 50px;
}

.services-service-item-overlay-content-item h3 {
  margin: 30px 0 10px 0;
  font-family: var(--main-title-font);
  font-size: 27px;
  font-weight: bold;
}

.services-service-item-overlay-content-item p {
  font-family: var(--paragraph-font);
  font-size: var(--paragraph-font-size);
  padding-bottom: 10px;
}

.services-service-item-overlay-content-button {
  width: 80%;
  margin: auto;
  padding: 25px 0 100px 0;
}

@media screen and (min-width: 768px) {
  .services-service-item {
    max-width: 250px;
  }
}
