.navbar {
  position: fixed;
  width: 100vw;
  margin-top: -120px;
  background-color: var(--white);
  font-family: var(--paragraph-font);
  text-transform: uppercase;
}

.navbar-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 14px 0 10px; */
  padding: 5px 0;
  /* background-color: var(--white); */
  border-radius: 40px;
  /* margin-top: 12px; */
  z-index: 1;
}

.navbar-links-logo {
  width: 50px;
}

.navbar-links-container {
  display: none;
}

.navbar-links-container .item {
  margin: 0 16px;
  color: var(--tale);
}

.item-link {
  width: 100%;
}

.navbar-links-container .btn {
  color: var(--white);
}

.navbar-links-container .btn--primary {
  display: none;
}

.navbar-links-container .item a:hover,
.navbar-links-menu .item:hover {
  color: var(--blue);
}

.navbar-links-menu .nav-button {
  color: var(--tale);
  height: 58px;
  width: 58px;
  cursor: pointer;
}

.navbar-links-menu-container .item {
  color: var(--tale);
  cursor: pointer;
  padding: 16px 0;
}

.navbar-links-menu-container {
  margin-top: 12px;
  position: absolute;
  top: 0;
  right: -100vw;
  z-index: -1;
  width: 100vw;
  background: var(--white);
  padding: 7rem 8vw;
  max-height: 95vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-gutter: stable;
}

.navbar-links-menu-container .item {
  width: 85vw;
  border-top: 1px solid var(--tale);
}

.navbar-links-menu-container .last {
  border-bottom: 1px solid var(--tale);
  margin-bottom: 48px;
}

.navbar-links-menu-container .item .item-services {
  display: flex;
  justify-content: space-between;
  align-items: flex-center;
}

.item-services-menu div {
  display: flex;
  flex-direction: column;
}

.item-services-menu .service-item:first-child {
  padding-top: 32px;
  padding-bottom: 8px;
}

.item-services-menu .service-item {
  padding: 8px 0;
}

@media screen and (min-width: 992px) {
  .navbar-links-container {
    flex-grow: 1;
    display: flex;
    justify-content: right;
    align-items: center;
  }

  .navbar-links-menu {
    display: none;
  }

  .item-services-menu div {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 15px;
    left: -15px;
    min-width: 300px;
    min-height: auto;
    max-height: 80vh;
    background-color: var(--white);
    overflow-y: scroll;
  }
  .item-services-menu .service-item {
    padding-left: 15px;
  }

  .main-page-button {
    display: none;
  }
}
