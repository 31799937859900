* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background-color: var(--color-bg);
}

html {
  position: relative;
  font-size: 18px;
  scroll-behavior: smooth;
}

ul {
  list-style-type: none;
}

a {
  color: unset;
  display: block;
  text-decoration: none;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.z-index-1 {
  position: relative;
  z-index: 1;
}
.z-index-2 {
  position: relative;
  z-index: 2;
}
.z-index-3 {
  position: relative;
  z-index: 3;
}

.gradient-dark-text {
  background: var(--gradient-dark-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-light-text {
  background: var(--gradient-light-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.clip-path-polygon {
  -webkit-clip-path: polygon(100% 0, 100% 0, 100% 90%, 0 100%, 0 10%);
  clip-path: polygon(100% 0, 100% 0, 100% 90%, 0 100%, 0 10%);
}

.clip-path-polygon-offer-first {
  -webkit-clip-path: polygon(100% 0, 100% 0, 100% 90%, 0 100%, 0 10%);
  clip-path: polygon(100% 0, 100% 0, 100% 90%, 0 100%, 0 10%);
}

.clip-path-polygon-top {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%, 0 10%);
  clip-path: polygon(100% 0, 100% 0, 140% 90%, 0 100%, 0 10%);
}

.container {
  margin: 0 3vw;
}

.section-container {
  display: flex;
  flex-direction: column;
  padding: 0 0.6rem;
}

.page-padding {
  padding: 130px 0;
}

.hover-effect-dark:hover {
  background: var(--gradient-bg-dark);
  color: var(--white);
}

.hover-effect-light:hover {
  background: var(--gradient-bg-light);
  color: var(--tale);
}

@media screen and (min-width: 576px) {
  .container {
    margin: 0 2vw;
  }
}

@media screen and (min-width: 768px) {
  .section-container {
    display: flex;
    flex-direction: row;
    padding: 0;
  }

  .container {
    margin: 0 3vw;
  }
}

@media screen and (min-width: 992px) {
  .container {
    margin: 0 4vw;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1150px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1440px) {
}

/* ----------------------------------------------
 * Generated by Animista on 2023-9-17 11:27:51
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * animation slide-right
 * ---------------------------------------------- */
.slide-right {
  -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(-100vw);
    transform: translateX(-100vw);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-100vw);
    transform: translateX(-100vw);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
/* ----------------------------------------*/

/* ----------------------------------------------
 * Generated by Animista on 2023-9-17 11:29:43
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ----------------------------------------------
 * animation slide-left */
.slide-left {
  -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100vw);
    transform: translateX(-100vw);
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100vw);
    transform: translateX(-100vw);
  }
}
/* ----------------------------------------*/

/* ----------------------------------------------
 * Generated by Animista on 2023-9-23 11:21:4
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * animation slide-top*/
.slide-top {
  -webkit-animation: slide-top 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(120px);
    transform: translateY(120px);
  }
  100% {
    -webkit-transform: translateY(-120px);
    transform: translateY(-120px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(120px);
    transform: translateY(120px);
  }
  100% {
    -webkit-transform: translateY(-120px);
    transform: translateY(-120px);
  }
}
/* ----------------------------------------*/

/* ----------------------------------------------
 * Generated by Animista on 2023-9-23 9:23:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * animation slide-bottom
 * ---------------------------------------------- */
.slide-bottom {
  -webkit-animation: slide-bottom 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bottom 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(120px);
    transform: translateY(120px);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(120px);
    transform: translateY(120px);
  }
}
/* ----------------------------------------*/

/* ----------------------------------------------
 * Generated by Animista on 2023-9-23 9:45:44
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista

 * animation scale-up-ver-top*/
.scale-up-ver-top {
  -webkit-animation: scale-up-ver-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-ver-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-ver-top {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}
@keyframes scale-up-ver-top {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}
/* ----------------------------------------*/
