.home-services-section {
  display: flex;
  gap: 2rem;
  padding: 0 1.5rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.home-services-text-section {
  text-align: center;
}

.home-services-text-section h2 {
  font-family: var(--main-title-font);
  font-size: var(--title-font-size);
}

.home-services-subtitle {
  display: flex;
  align-items: center;
  margin: 3.5rem 0;
}

.home-services-subtitle h3 {
  font-family: var(--secondary-title-font);
  font-size: 1rem;
  color: var(--tale);
}

.home-services-subtitle .title-line {
  width: 60px;
  height: 0.5px;
  background-color: var(--blue);
  margin: 0 40px 0 0;
}

.home-services-list-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.home-services-list-item:last-child {
  margin-bottom: 3.5rem;
}

.home-services-list-item .dotted-line {
  width: 100%;
  margin: 1.5rem 0.8rem;
  height: 5px;
  border-bottom: 3px dotted var(--tale);
  order: 4;
}

.home-services-list-item:last-child .dotted-line {
  display: none;
}

.home-services-list-item .text {
  order: 1;
  width: 100%;
}

.home-services-list-item .button {
  order: 3;
  width: 50%;
  display: flex;
  justify-content: end;
  align-self: center;
}
.member-of-lines {
  display: flex;
  max-width: 300px;
  margin: 50px auto 50px auto;
  justify-content: space-between;
  align-items: center;
}

.home-services-list-item h4 {
  font-size: var(--sec-title-font-size);
  font-family: var(--paragraph-title-font);
  color: var(--tale);
  font-weight: bold;
  margin-bottom: 10px;
}

.home-services-list-item p {
  font-size: var(--paragraph-font-size);
  font-family: var(--paragraph-font);
  color: var(--tale);
  font-weight: bold;
  margin-bottom: 10px;
}

.home-services-button {
  padding: 0 2rem;
}
.btn--primary {
  width: 100%;
}

.home-services-list-object {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.home-services-img img {
  width: 100%;
  max-width: 450px;
  border-radius: 30px;
  -webkit-box-shadow: 0px 59px 100px -24px var(--tale);
  box-shadow: 0px 59px 100px -24px var(--tale);
}

@media screen and (min-width: 700px) {
  .home-services-text-section {
    text-align: left;
  }
  .home-services-list-item .dotted-line {
    width: 50%;
  }

  .home-services-list-object {
    flex-direction: row;
    align-items: center;
  }

  .home-services-subtitle {
    margin: 3rem 0;
  }

  .home-services-list-item p {
    margin-right: 20px;
  }

  .home-services-img {
    width: 100%;
  }
}
