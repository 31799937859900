.services-service-item-container {
  margin: 100px auto 0 auto;
  display: flex;
  flex-direction: wrap;
  flex-flow: row wrap;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  text-align: center;
}

.services-service-item-container-main-title {
  text-align: center;
  padding-bottom: 20px;
  font-size: var(--title-font-size);
  font-family: var(--main-title-font);
  color: var(--white);
  width: 100%;
}

/* moving background */
.services-moving-background {
  background: var(--blue);
  min-height: 100vh;
  display: flex;
  overflow: hidden;
  position: relative;
}

.blob-c {
  height: 100vh;
  overflow: hidden;
  position: fixed;
  width: 100vw;
  filter: blur(80px);
  z-index: 1;
}

.shape-blob {
  background: var(--tale);
  height: 60px;
  width: 80px;
  border-radius: 40% 50% 30% 40%;
  animation: transform 18s ease-in-out infinite both alternate,
    movement_one 12s ease-in-out infinite both;
  opacity: 0.7;
  position: absolute;
  left: 75%;
  top: 40%;
}
.shape-blob.one {
  background: var(--tale);
  height: 150px;
  width: 200px;
  left: 3%;
  top: 10%;
  transform: rotate(-180deg);
  animation: transform 8s ease-in-out infinite both alternate,
    movement_two 20s ease-in-out infinite both;
}

.shape-blob.two {
  background: var(--tale);
  height: 150px;
  width: 150px;
  left: 60%;
  top: 15%;
  transform: rotate(-180deg);
  animation: transform 10s ease-in-out infinite both alternate,
    movement_two 10s ease-in-out infinite both;
}

.shape-blob.three {
  background: var(--tale);
  height: 150px;
  width: 150px;
  left: 80%;
  top: 30%;
  transform: rotate(-180deg);
  animation: transform 7s ease-in-out infinite both alternate,
    movement_two 23s ease-in-out infinite both;
}

.shape-blob.four {
  background: var(--tale);
  height: 100px;
  width: 180px;
  left: 5%;
  top: 40%;
  transform: rotate(-180deg);
  animation: transform 17s ease-in-out infinite both alternate,
    movement_two 13s ease-in-out infinite both;
}

.shape-blob.five {
  background: var(--tale);
  height: 100px;
  width: 180px;
  left: 48%;
  top: 35%;
  transform: rotate(-180deg);
  animation: transform 12s ease-in-out infinite both alternate,
    movement_two 18s ease-in-out infinite both;
}

.shape-blob.six {
  background: var(--tale);
  height: 70px;
  width: 100px;
  left: 40%;
  top: 20%;
  transform: rotate(-180deg);
  animation: transform 5s ease-in-out infinite both alternate,
    movement_two 5s ease-in-out infinite both;
}

.shape-blob.seven {
  background: var(--tale);
  height: 150px;
  width: 200px;
  left: 10%;
  top: 80%;
  transform: rotate(-180deg);
  animation: transform 8s ease-in-out infinite both alternate,
    movement_two 20s ease-in-out infinite both;
}

.shape-blob.eight {
  background: var(--tale);
  height: 250px;
  width: 150px;
  left: 70%;
  top: 70%;
  transform: rotate(-180deg);
  animation: transform 10s ease-in-out infinite both alternate,
    movement_two 10s ease-in-out infinite both;
}

.shape-blob.nine {
  background: var(--tale);
  height: 150px;
  width: 250px;
  left: 35%;
  top: 55%;
  transform: rotate(-180deg);
  animation: transform 7s ease-in-out infinite both alternate,
    movement_two 23s ease-in-out infinite both;
}

.shape-blob.ten {
  background: var(--tale);
  height: 100px;
  width: 80px;
  left: 15%;
  top: 60%;
  transform: rotate(-180deg);
  animation: transform 17s ease-in-out infinite both alternate,
    movement_two 13s ease-in-out infinite both;
}

.shape-blob.eleven {
  background: var(--tale);
  height: 100px;
  width: 80px;
  left: 48%;
  top: 90%;
  transform: rotate(-180deg);
  animation: transform 12s ease-in-out infinite both alternate,
    movement_two 18s ease-in-out infinite both;
}

.shape-blob.twelve {
  background: var(--tale);
  height: 70px;
  width: 100px;
  left: 45%;
  top: 75%;
  transform: rotate(-180deg);
  animation: transform 5s ease-in-out infinite both alternate,
    movement_two 5s ease-in-out infinite both;
}

@keyframes transform {
  0%,
  100% {
    border-radius: 33% 67% 70% 30% / 30% 40% 70% 70%;
  }
  20% {
    border-radius: 37% 63% 51% 49% / 37% 35% 35% 63%;
  }
  40% {
    border-radius: 36% 64% 64% 36% / 64% 48% 52% 26%;
  }
  60% {
    border-radius: 37% 63% 51% 49% / 30% 30% 70% 73%;
  }
  80% {
    border-radius: 40% 60% 42% 58% / 51% 51% 49% 59%;
  }
}

@keyframes movement_one {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: translate(50%, 20%) rotateY(10deg) scale(1);
  }
}

@keyframes movement_two {
  0%,
  500% {
    transform: none;
  }
  50% {
    transform: translate(50%, 20%) rotate(-200deg) scale(1.3);
  }
}

@media screen and (min-width: 768px) {
  .shape-blob.one {
    height: 250px;
    width: 300px;
  }

  .shape-blob.two {
    height: 250px;
    width: 250px;
  }

  .shape-blob.three {
    height: 250px;
    width: 250px;
  }

  .shape-blob.four {
    height: 200px;
    width: 280px;
  }

  .shape-blob.five {
    height: 200px;
    width: 280px;
  }

  .shape-blob.six {
    height: 170px;
    width: 200px;
  }

  .shape-blob.seven {
    height: 250px;
    width: 300px;
  }

  .shape-blob.eight {
    height: 350px;
    width: 250px;
  }

  .shape-blob.nine {
    height: 250px;
    width: 350px;
  }

  .shape-blob.ten {
    height: 200px;
    width: 180px;
  }

  .shape-blob.eleven {
    height: 200px;
    width: 180px;
  }

  .shape-blob.twelve {
    height: 170px;
    width: 200px;
  }
}

@media screen and (min-width: 1199px) {
  .services-service-item-container {
    max-width: 990px;
  }
}
