.btn--primary {
  background: radial-gradient(
    circle at 25% 0%,
    var(--lime) 0%,
    var(--blue) 100%
  );
  color: var(--white);
  padding: 1rem;
  font-weight: 700;
  border-radius: 50px;
  cursor: pointer;
  max-width: fit-content;
  margin: auto;
  font-family: var(--button-font);
}

.btn--primary:hover {
  background: radial-gradient(
    circle at 25% 0%,
    var(--blue) 0%,
    var(--lime) 100%
  );
}

.btn--small--round:hover {
  cursor: pointer;
  margin-right: -3px;
  transition: all ease-in-out 0.3s;
}

.btn--outline {
  display: inline-block;
  border: 1px solid var(--grey);
  border-radius: 50px;
  cursor: pointer;
  padding: 0.5rem 1rem;
  color: var(--grey);
}

.btn--outline-dark {
  border: 1px solid var(--tale);
  border-radius: 50px;
  cursor: pointer;
  padding: 4px 4px 4px 18px;
  color: var(--tale);
  font-family: var(--button-font);
}

.btn--outline-dark a {
  line-height: 40px;
  vertical-align: top;
}

.btn--outline-dark .round-arrow svg {
  width: 40px;
  height: 40px;
  padding: 5px;
  vertical-align: top;
  margin-left: 5px;
  border: 1px solid;
  border-radius: 100%;
}

.btn--outline-dark-small {
  border: 1px solid var(--tale);
  font-size: var(--button-small-font-size);
  border-radius: 50px;
  cursor: pointer;
  padding: 3px 3px 3px 12px;
  color: var(--tale);
  font-family: var(--button-font);
}

.btn--outline-dark-small a {
  line-height: 19px;
  vertical-align: top;
}

.btn--outline-dark-small .round-arrow svg {
  width: 19px;
  height: 19px;
  padding: 1px;
  vertical-align: top;
  margin-left: 5px;
  border: 1px solid;
  border-radius: 100%;
}

.btn--outline-dark:hover,
.btn--outline-dark-small:hover {
  background: var(--gradient-bg-dark);
  color: var(--white);
}

.btn-small-round-up {
  position: fixed;
  color: var(--white);
  background-color: var(--tale);
  padding: 0.3rem;
  border-radius: 50%;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  border: 1px solid var(--grey);
  z-index: 10;
}
