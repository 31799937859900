@import url("https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:wght@300;400;700&family=Croissant+One&family=Lato:wght@300;400&family=Manrope:wght@300;400;500&family=Roboto+Slab:wght@300;400;500&family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Neuton:wght@300&family=Nunito:wght@600&display=swap");

:root {
  --Neuton: "Neuton", serif;
  --Nunito: "Nunito", sans-serif;

  --main-title-font: var(--Nunito);
  --secondary-title-font: var(--Nunito);
  --paragraph-title-font: var(--Nunito);
  --paragraph-font: var(--Nunito);
  --price-font: var(--Nunito);
  --button-font: var(--Nunito);

  --overlay-dark: rgba(40, 42, 42, 0.5);
  --gradient-dark-text: linear-gradient(90deg, var(--blue) 0%, var(--tale) 47%);
  --gradient-light-text: linear-gradient(
    90deg,
    rgba(245, 245, 245, 1) 0%,
    rgba(255, 255, 255, 1) 67%
  );

  --gradient-bg-dark: radial-gradient(
    circle at 25% 0%,
    var(--blue) 0%,
    var(--tale) 50%
  );

  --main-title-font-size: 2.2rem;
  --main-secondary-title-font-size: 1.4rem;
  --main-third-title-font-size: 1.4rem;
  --paragraph-font-size: 1.2rem;
  --title-font-size: 1.8rem;
  --sec-title-font-size: 1.4rem;
  --button-small-font-size: 0.7rem;

  --gradient-bg-light: radial-gradient(
    circle at 25% 0%,
    var(--white) 0%,
    var(--grey) 50%
  );

  --color-bg: var(--grey);
  --white: rgb(255, 255, 255);
  --grey: rgb(245, 245, 245);
  --tale: rgb(51, 152, 157);
  --blue: rgb(28, 90, 124);
  --dark-grey: rgb(64, 81, 78);
  --lime: rgb(85, 185, 131);
  --dark-lime: rgba(85, 185, 131, 0.8);
}
